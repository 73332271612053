const BASE_URL = '/create-account/corporate';

export const corporateRoutesConfig = {
  steps: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/steps`],
    nextUrl: [`${BASE_URL}/company`],
  },
  company: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/company`],
    nextUrl: '',
  },
  bank: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/bank`],
    nextUrl: '',
  },
  directorDetails: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/director-details`],
    nextUrl: '',
  },
  confirmDirector: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/confirm-director`],
    nextUrl: '',
  },
  addSignatory: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/add-signatory`],
    nextUrl: '',
  },
  confirmSignatory: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/confirm-signatory`],
    nextUrl: '',
  },
  uploadDocument: {
    prevUrl: '',
    currentUrl: [`${BASE_URL}/upload-document`],
    nextUrl: '',
  },
};
